/* Global rules */
$color-1: #f7f7f7;
$color-2: #00c1ec;
$color-3: #e7f7fe;
$color-4: rgb(141, 141, 141);
$black: rgb(40, 40, 40);
$red: rgb(253, 87, 87);
/* Other rules */
#not-mail {
  display: none;
  position: absolute;
  top: 8px;
  color: $red;
  right: 0;
  transform-origin: 50% 50%;
}
@keyframes dongle {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(-10px, 0px);
  }
  20% {
    transform: translate(10px, 0px);
  }
  30% {
    transform: translate(-10px, 0px);
  }
  40% {
    transform: translate(10px, 0px);
  }
  50% {
    transform: translate(-10px, 0px);
  }
  60% {
    transform: translate(10px, 0px);
  }
  70% {
    transform: translate(-10px, 0px);
  }
  80% {
    transform: translate(10px, 0px);
  }
  90% {
    transform: translate(-10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
